exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-activities-index-tsx": () => import("./../../../src/pages/activities/index.tsx" /* webpackChunkName: "component---src-pages-activities-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-price-index-tsx": () => import("./../../../src/pages/price/index.tsx" /* webpackChunkName: "component---src-pages-price-index-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-pages-works-index-tsx": () => import("./../../../src/pages/works/index.tsx" /* webpackChunkName: "component---src-pages-works-index-tsx" */),
  "component---src-templates-activity-tsx": () => import("./../../../src/templates/activity.tsx" /* webpackChunkName: "component---src-templates-activity-tsx" */),
  "component---src-templates-work-tsx": () => import("./../../../src/templates/work.tsx" /* webpackChunkName: "component---src-templates-work-tsx" */)
}

