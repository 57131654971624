import "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "./src/styles/global.css";

import React from "react";
import { Provider } from "./src/context";

export const wrapRootElement = ({ element }) => <Provider>{element}</Provider>;
